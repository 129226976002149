import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {BigText, BoldText, CustomButton, FormContainer, Icon, IconContainer, SectionContainer, TextContainer, VerticalSpace} from '../styles/ContactUs.module'

const ContactUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionContainer id="contact">
      <Container>
        <Row>
          <Col md={6}>
            <FormContainer>
              <BigText>{t('contactus_description')}</BigText>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>{t('name')}</Form.Label>
                  <Form.Control type="text" placeholder={t('enter_your_name')} />
                </Form.Group>
                <VerticalSpace />
                <Form.Group controlId="formEmail">
                  <Form.Label>{t('email')}</Form.Label>
                  <Form.Control type="email" placeholder={t('enter_your_email')} />
                </Form.Group>
                <VerticalSpace />
                <Form.Group controlId="formMessage">
                  <Form.Label>{t('message')}</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder={t('enter_your_message')} />
                </Form.Group>
                <VerticalSpace />
                <CustomButton type="submit">
                {t('submit')}
                </CustomButton>
              </Form>
            </FormContainer>
          </Col>
          <Col md={6}>
            <TextContainer>
              <h2>{t('contactus_reachus')}</h2>
              {/* <BoldText>{t('contactus_description')}</BoldText> */}
              <VerticalSpace />
              <IconContainer>
                <Icon>
                  <i className="fas fa-building"></i>
                </Icon>
                <BoldText>{t('contactus_address')}</BoldText>
              </IconContainer>
              <IconContainer>
                <Icon>
                  <i className="fas fa-phone-alt"></i>
                </Icon>
                <BoldText>{t('contactus_callus')}</BoldText>
              </IconContainer>
              <IconContainer>
                <Icon>
                  <i className="fas fa-envelope"></i>
                </Icon>
                <BoldText>{t('contactus_emailus')}</BoldText>
              </IconContainer>
            </TextContainer>
          </Col>
        </Row>
      </Container>
    </SectionContainer>
  );
};

export default ContactUs;
