import { ServiceConstants } from "./ServiceConstants";

export interface AppConfig {
    currencyName: string;
    currencySymbol: string;
    // Add more fields as needed
  }
  const configUrl =
    ServiceConstants.URL_VEHICLE_SERVICE + ServiceConstants.URL_CONFIGURATION_ENDPOINT;
  export const ConfigService = {
    async fetchConfigurations(): Promise<AppConfig> {
      const response = await fetch(configUrl); // Adjust the API endpoint
      if (!response.ok) {
        throw new Error('Failed to fetch configurations');
      }
      const configData = await response.json();
      return configData;
    }
  };
  