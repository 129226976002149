import styled from 'styled-components';

export const SectionContainer = styled.div`
  padding: 20px 0;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  margin: 10px 0;
`;

export const FormContainer = styled.div`
  padding: 20px;
`;

export const TextContainer = styled.div`
  padding: 20px;
`;

export const BigText = styled.p`
  font-size: 1.25rem;
  font-weight: bold;
`;

export const BoldText = styled.p`
  font-weight: bold;
`;

export const VerticalSpace = styled.div`
  height: 15px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  // background-color: #495057;
  display: flex;
  padding: 5px;
  // align-items: center;
  justify-content: center;
  color: #495057;
  margin-right: 10px;
`;

export const CustomButton = styled.button`
  background-color: #ffc107;
  border: none;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  max-width: 100px;
  width: 100%;
  &:hover {
    background-color: #e0a800;
  }
`;
