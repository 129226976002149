
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const SectionContainer = styled(Row)`
  background-color: #e9ecef;
  margin: 10px 0;
  padding: 10px;
  align-items: center;
  min-height: 700px;
`;

export const TextCol = styled(Col)`
  background-color: #ffffff;
  margin: 10px 0;
  padding: 10px;
  align-items: center;
`;

export const Image = styled.img`
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 100%;
  height: auto;
  max-width: 1000px;
  margin: 10px;
`;

// export const TextContent = styled.div`
//   padding: 10px;
//   // background-color: #ffffff;
// `;

// export const TextContentInside = styled.div`
//   margin-bottom: '20px'
// `;

// export const BigText = styled.p`
//   font-size: 1.5rem;
//   // font-weight: bold;
// `;

// export const DetailText = styled.p`
//   font-size: 1.25rem;
//   color: #ffB921;
// `;


export const BoxWrapper = styled.div`
  display: flex;
  height:100%;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 12px;
  padding: 16px;
  margin: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // text-align: center;
  text-align: left;
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  
  &:hover {
    transform: translateY(-10px); /* Move the box up by 20px */
  }
    /* Apply hover effect to the Icon */
  &:hover img {
    transform: scaleX(-1); /* Flip the icon horizontally */
    transition: transform 0.5s ease; /* Smooth transition for icon flipping */
  }
`;

// export const Icon = styled.div`
//   font-size: 24px;
//   margin-bottom: 8px;
// `;

export const Icon = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 8px;
  object-fit: cover; /* Ensures the image covers the area */
  border-radius: 50%; /* Optional: makes the image circular */
  transition: transform 0.5s ease;
`;
export const BigText = styled.h3`
  font-size: 20px;
  margin: 8px 0;
`;

export const DetailText = styled.p`
  font-size: 14px;
  line-height: 1.5;
`;

export const TextContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: '20px'
`;

export const TextContentInside = styled.div`
  flex: 1;
  max-width: 300px; // Adjust based on your design needs
  min-width: 200px;
  align-items: center;
`;