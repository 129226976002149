// import React from 'react';
// import styles from '../styles/Footer.module.css';

// const Footer: React.FC = () => {
//   return (
//     <footer className={styles.footer}>
//       <div className={styles.footerContent}>
//         <p>© 2024 Car Rental Inc. All rights reserved.</p>
//         <p><a href="/license">License Agreement</a></p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;
// import React from 'react';
// import '../styles/Footerupdated.css'
import React from 'react';
import {
  FooterContainer,
  FooterText,
  Icon,
  ImageLink,
  Separator,
  Image
} from '../styles/Footer.module';
import '@fortawesome/fontawesome-free/css/all.min.css';  // Import Font Awesome CSS
import { Container, Row, Col } from 'react-bootstrap';
import Image1 from '../images/playstore.png';
import Image2 from '../images/ios.png';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Container>
        <Row>
          <Col md={6} className="d-flex align-items-center">
            <ImageLink href="https://example1.com" target="_blank" rel="noopener noreferrer">
              <Image src={Image1} alt="Link to Example 1" />
            </ImageLink>
            <ImageLink href="https://example2.com" target="_blank" rel="noopener noreferrer">
              <Image src={Image2} alt="Link to Example 2" />
            </ImageLink>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-md-end">
            <Icon href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </Icon>
            <Icon href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </Icon>
            <Icon href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </Icon>
          </Col>
        </Row>
        <Separator />
        <Row>
          <Col>
            <FooterText>© 2024 Zoom Rides. All Rights Reserved.</FooterText>
          </Col>
        </Row>
        <Row>
          <Col>
            <FooterText>Trademark and license agreements here.</FooterText>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default Footer;