import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeft, FaShieldAlt, FaCheckCircle, FaClock } from 'react-icons/fa';
import {
  SummaryContainer,
  BackButton,
  TripSection,
  SectionTitle,
  DetailColumn,
  IconTextContainer,
  IconWrapper,
  IconText,
  VehicleCard,
  VehicleImage,
  VehicleTypeDiv,
  VehiclePrice,
  BackButtonContainer,
  CustomButton,
  LoadingContainer
} from '../styles/BookingSummary';
import vehicleImage from '../images/home1.jpg'; // Example image path
import BookingModal from './BookingModal';
import { BookingData, Vehicle, VehicleType } from '../types';
import { BookingService } from '../services/BookingService';
import { ServiceConstants } from '../services/ServiceConstants';
import { formatReturnDate } from '../utils/Utils';
import { useConfig } from '../context/ConfigContext';

const BookingSummary: React.FC = () => {

  const { config, loadingC } = useConfig();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const bookingData = location.state as BookingData; // Use the interface to type the state
  const [showModal, setShowModal] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState<VehicleType | null>(null);

  // const vehicles = [
  //   { image: vehicleImage, type: 'Sedan', price: '$100', km: '100 km', driverCharges: '$10/hr', nightCharges: '$20', id: 1 },
  //   { image: vehicleImage, type: 'SUV', price: '$150', km: '150 km', driverCharges: '$15/hr', nightCharges: '$25', id: 2 },
  //   // Add more vehicles as needed
  // ];
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [vehiclesTypes, setVehiclesTypes] = useState<VehicleType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const defaultVehicleType: VehicleType = {
    type: 'Sedan',
    name: 'Car',
    price: '1200',
    km: '300',
    driverCharges: '50',
    nightCharges: '800',
    id: 0,
    image: '', // Replace with a valid default image path
    bookingPricePerUnit: 0,
    active: false,
    bookingUnit: 0,
    createrId: null, // or use an appropriate default value
  pricing: {
    id: null, // or use an appropriate default value
    carType: '',
    baseRate: 0,
    mileageRate: 0,
    gpsRate: 0,
    childSeatRate: 0,
    wifiRate: 0,
    createdBy: null, // or use an appropriate default value
    mileageEnabled: false,
    gpsEnabled: false,
    childSeatEnabled: false,
    wifiEnabled: false,
  },
  };

  const getVehicleList = async () => {
    setLoading(true);
    const response = await BookingService.getBookingSearchList()
        .then((result) => {
            setLoading(false);
            if (result.ok) {
                return result.json();
            }
            const httpErrorInfo = {
                errorstatus: result.status,
                statusText: result.statusText,
                url: result.url,
            };
            return httpErrorInfo;
        })
        .then((myJson) => {
            setLoading(false);
            console.log(myJson);
            if ("errorstatus" in myJson) {
                if (myJson.errorstatus === 401) {
                    console.log("Unauthorized");
                    // setError("Please check username and password");
                }
            } else {
              const updatedVehicleTypes = myJson.vehicleType.map((vehicleType: VehicleType) => ({
                ...defaultVehicleType, // Default values
                ...vehicleType, // Overwrite with fetched data
              }));
              setVehiclesTypes(updatedVehicleTypes);
              setVehicles(myJson.vehicles);
            }
        });
};


  useEffect(() => {
    getVehicleList()
    .then(() => console.log("done"))
    .catch((error: TypeError) => {
        console.log(error);
        throw new Error(
            "There was an error retrieving the getVehicleList. Please try again."
        );
    });
  }, [bookingData]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleBookNowClick = (vehicle: VehicleType) => {
    setSelectedVehicleType(vehicle);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const vehicleimageurl =
        ServiceConstants.URL_VEHICLE_SERVICE + ServiceConstants.URL_VEHICLE_TYPE_IMAGE_ENDPOINT;

  return (
    <SummaryContainer>
      {/* <BackButtonContainer>
        <Button onClick={handleBackClick} variant="secondary">
          <FaArrowLeft /> {t('back')}
        </Button>
      </BackButtonContainer> */}
      <TripSection>
        <SectionTitle>{t('trip')}</SectionTitle>
        <Row>
          <Col md={6} >
            <DetailColumn>
              <p>{t('pickupLocation')}</p>
              <h3>{bookingData.pickupLocation?.city}</h3>
              <p>{t('pickupDate')}</p>
              <h3>{formatReturnDate(bookingData.pickupDate)}</h3>
            </DetailColumn>
          </Col>
          <Col md={6}>
            <DetailColumn>
              <p>{t('destinationLocation')}</p>
              <h3>{bookingData.destinationLocation?.city}</h3>
              <p>{t('returnDate')}</p>
              <h3>{formatReturnDate(bookingData.returnDate)}</h3>
            </DetailColumn>
          </Col>
        </Row>
      </TripSection>
      <IconTextContainer>
        <IconWrapper>
          <FaShieldAlt size={30} color="#ffc107" />
          <IconText>{t('safetyFirst')}</IconText>
        </IconWrapper>
        <IconWrapper>
          <FaCheckCircle size={30} color="#ffc107" />
          <IconText>{t('confirmedCab')}</IconText>
        </IconWrapper>
        <IconWrapper>
          <FaClock size={30} color="#ffc107" />
          <IconText>{t('alwaysOnTime')}</IconText>
        </IconWrapper>
      </IconTextContainer>
      {loading ? (
        <LoadingContainer>
          <Spinner animation="border" variant="primary" />
        </LoadingContainer>
      ) : (
      <Row>
        {vehiclesTypes.map(vehicletype => (
          <Col md={4} key={vehicletype.id}>
            <VehicleCard>
              <VehicleImage src={vehicleimageurl+vehicletype.id} alt={vehicletype.type} />
              <VehicleTypeDiv>{vehicletype.name}</VehicleTypeDiv>
              <VehiclePrice>{t('price')}: {vehicletype.pricing.baseRate} {config?.currencySymbol} {t('perHour')}</VehiclePrice>
              {/* <p>{t('includedKm')}: {vehicletype.km}</p>
              <p>{t('driverCharges')}: {vehicletype.driverCharges}</p>
              <p>{t('nightCharges')}: {vehicletype.nightCharges}</p> */}
              <CustomButton type="submit" onClick={() => handleBookNowClick(vehicletype)}>
                {t('bookNow')}
              </CustomButton>
            </VehicleCard>
          </Col>
        ))}
      </Row>
    )}
      <BookingModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        bookingData={bookingData}
        selectedVehicle={selectedVehicleType!} // Pass selected vehicle to modal
      />
    </SummaryContainer>
  );
};

export default BookingSummary;

