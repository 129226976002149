export const formatReturnDate = (returnDate: string): string => {
    const date = new Date(returnDate);
  
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  
    return `${formattedDate}, ${formattedTime}`;
  };