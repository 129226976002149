import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { 
    TopSectionContainer, 
    FormContainer, 
    CustomForm, 
    FormControlContainer, 
    FormControl, 
    IconWrapper, 
    CustomButton,
    Heading
} from '../styles/TopSection.module';
import { FaMapMarkerAlt, FaCalendarAlt, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { CityType } from '../types';
import { BookingService } from '../services/BookingService';
import Select, { SingleValue } from 'react-select';

const TopSection: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Utility function to format dates to the local time zone
  const formatLocalISOString = (date: Date) => {
    const timezoneOffset = date.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    const localISOTime = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, 16);
    return localISOTime;
  };

  // Set the current date and time
  const now = new Date();
  const pickupDateISOString = formatLocalISOString(new Date(now.getTime() + 2.5 * 60 * 60 * 1000)); // 2 hours 30 minutes from now
  const returnDateISOString = formatLocalISOString(new Date(now.getTime() + 12 * 60 * 60 * 1000 + 1)); // 12 hours and 1 millisecond from now

  // State for form data
  const [formData, setFormData] = useState({
    pickupLocation: null as CityType | null,
    destinationLocation: null as CityType | null,
    pickupDate: pickupDateISOString,
    returnDate: returnDateISOString,
    email: '',
    phone: ''
  });

  // State for validation error message
  const [error, setError] = useState<string | null>(null);

  const [cityTypes, setCityTypes] = useState<CityType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const getCityList = async () => {
    setLoading(true);
    const response = await BookingService.getBookingCityList()
        .then((result) => {
            setLoading(false);
            if (result.ok) {
                return result.json();
            }
            const httpErrorInfo = {
                errorstatus: result.status,
                statusText: result.statusText,
                url: result.url,
            };
            return httpErrorInfo;
        })
        .then((myJson) => {
            setLoading(false);
            console.log(myJson);
            if ("errorstatus" in myJson) {
                if (myJson.errorstatus === 401) {
                    console.log("Unauthorized");
                    // setError("Please check username and password");
                }
            } else {
              setCityTypes(myJson);
            }
        });
};
useEffect(() => {
  getCityList()
  .then(() => console.log("done"))
  .catch((error: TypeError) => {
      console.log(error);
      throw new Error(
          "There was an error retrieving the getCityList. Please try again."
      );
  });
}, []);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = (): boolean => {
    const pickupDate = new Date(formData.pickupDate);
    const returnDate = new Date(formData.returnDate);

    // Clear previous errors
    setError(null);

    // Validate pickup and destination locations
    if (!formData.pickupLocation) {
      setError(t('pickupLocationRequired'));
      return false;
    }
    if (!formData.destinationLocation) {
      setError(t('destinationLocationRequired'));
      return false;
    }

    // Validate email or mobile number
    if (!formData.email && !formData.phone) {
      setError(t('contactInfoRequired'));
      return false;
    }

    // Validate dates
    if (pickupDate >= returnDate) {
      setError(t('pickupDateBeforeReturnDate'));
      return false;
    }
    if (pickupDate < new Date()) {
      setError(t('pickupDateTooSoon'));
      return false;
    }

    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      navigate('/booking-summary', { state: formData });
    }
  };


  const handleSelectChange = (selectedOption: any, actionMeta: any) => {
    const { name } = actionMeta;
    setFormData(prevState => ({
      ...prevState,
      [name]: selectedOption ? cityTypes.find(city => city.city === selectedOption.value) || null : null
    }));
  };

  const cityOptions = cityTypes.map(city => ({
    value: city.city,
    label: `${city.city}, ${city.state}`
  }));

  // Convert formData values to match the options format
  const selectedPickupOption = formData.pickupLocation ? {
    value: formData.pickupLocation.city,
    label: `${formData.pickupLocation.city}, ${formData.pickupLocation.state}`
  } : null;
  
  const selectedDestinationOption = formData.destinationLocation ? {
    value: formData.destinationLocation.city,
    label: `${formData.destinationLocation.city}, ${formData.destinationLocation.state}`
  } : null;
  return (
    <TopSectionContainer>
      <Container>
        <Row>
          <Col md={6}>
            <FormContainer>
              <Heading>{t('bookYourRide')}</Heading>
              <CustomForm onSubmit={handleSubmit}>
              <FormControlContainer>
                  <Select
                    name="pickupLocation"
                    options={cityOptions}
                    placeholder={t('pickupLocation')}
                    onChange={handleSelectChange}
                    value={selectedPickupOption}
                    isClearable 
                    styles={{
                      control: (provided, state)  => ({
                        ...provided,
                        height: '55px',         // Set the width to 60px
                        backgroundColor: '#f8f9fa',
                         // Set the background color to grey
                      }),
                      menu: (provided) => ({
                        ...provided,
                        height: '165px',   
                        backgroundColor: '#f8f9fa',      // Ensure the dropdown menu has the same width
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? 'white' : 'black',  // Text color black for non-selected, white for selected
                        backgroundColor: state.isSelected ? '#666' : provided.backgroundColor, // Optional: background color for selected option
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: 'black',  // Ensure selected value's text is black
                      }),
                    }}
                  />
                  <IconWrapper><FaMapMarkerAlt size={20} /></IconWrapper>
                </FormControlContainer>
                <FormControlContainer>
                  <Select
                    name="destinationLocation"
                    options={cityOptions}
                    placeholder={t('destinationLocation')}
                    onChange={handleSelectChange}
                    value={selectedDestinationOption}
                    isClearable
                    styles={{
                      control: (provided, state)  => ({
                        ...provided,
                        height: '55px',         // Set the width to 60px
                        backgroundColor: '#f8f9fa',
                         // Set the background color to grey
                      }),
                      menu: (provided) => ({
                        ...provided,
                        height: '165px',   
                        backgroundColor: '#f8f9fa',      // Ensure the dropdown menu has the same width
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? 'white' : 'black',  // Text color black for non-selected, white for selected
                        backgroundColor: state.isSelected ? '#666' : provided.backgroundColor, // Optional: background color for selected option
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: 'black',  // Ensure selected value's text is black
                      }),
                    }}
                  />
                  <IconWrapper><FaMapMarkerAlt size={20} /></IconWrapper>
                </FormControlContainer>
                <FormControlContainer>
                  <FormControl
                    type="datetime-local"
                    name="pickupDate"
                    value={formData.pickupDate}
                    onChange={handleChange}
                  />
                  <IconWrapper><FaCalendarAlt size={20} /></IconWrapper>
                </FormControlContainer>
                <FormControlContainer>
                  <FormControl
                    type="datetime-local"
                    name="returnDate"
                    value={formData.returnDate}
                    onChange={handleChange}
                  />
                  <IconWrapper><FaCalendarAlt size={20} /></IconWrapper>
                </FormControlContainer>
                <FormControlContainer>
                  <FormControl
                    type="email"
                    name="email"
                    placeholder={t('email_top')}
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <IconWrapper><FaEnvelope size={20} /></IconWrapper>
                </FormControlContainer>
                <FormControlContainer>
                  <FormControl
                    type="text"
                    name="phone"
                    placeholder={t('mobileNumber')}
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <IconWrapper><FaPhoneAlt size={20} /></IconWrapper>
                </FormControlContainer>
                {error && (
                <div style={{ color: 'red', marginBottom: '15px' }}>
                  {error}
                </div>
                )}
                <CustomButton type="submit">
                  {t('submit')}
                </CustomButton>
              </CustomForm>
            </FormContainer>
          </Col>
        </Row>
      </Container>
    </TopSectionContainer>
  );
};

export default TopSection;
