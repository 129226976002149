import styled from 'styled-components';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select'; 
import { FaMapMarkerAlt, FaCalendarAlt, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import backgroundImage from '../images/home3.jpg'; // Adjust the path according to your folder structure

// Styled components
export const TopSectionContainer = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  padding: 50px 0;
  color: #ffffff;
  min-height: 700px; /* Ensure the section is tall enough */
`;

export const FormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.8); /* Light grey background */
  padding: 30px;
  border-radius: 10px;
`;

export const CustomForm = styled(Form)`
  & .form-group {
    margin-bottom: 15px;
  }
`;

export const FormControlContainer = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

export const FormControl = styled(Form.Control)`
  background-color: #f8f9fa; /* Light grey background */
  border-radius: 5px; /* Rounded borders */
  padding-right: 40px; /* Space for icon */
  height: 55px; /* Increased height by 5px */
  font-size: 1rem;
`;

export const FormSelect = styled(Select)`
  background-color: #f8f9fa; /* Light grey background */
  border-radius: 25px; /* Rounded borders */
  width: 100%; /* Full width */
  max-width: 400px; /* Adjust max width as needed */
  font-size: 1rem;
  height: 55px;

  /* Apply styles to make it look like other form controls */
  .react-select__control {
    background-color: #f8f9fa; /* Same background color as other inputs */
    border: 1px solid #ced4da; /* Border to match other controls */
    box-shadow: none; /* Remove shadow */
    border-radius: 25px; /* Rounded corners */
    height: 55px; /* Height to match other controls */
    display: flex; /* Ensure full height for content */
    align-items: center; /* Center the content vertically */
    padding-left: 15px; /* Add padding similar to other inputs */
  }

  /* Ensure the single-value container has consistent height */
  .react-select__single-value {
    line-height: 55px; /* Match the height */
    height: 55px;
  }

  /* Ensure the placeholder has consistent height */
  .react-select__placeholder {
    line-height: 55px; /* Match the height */
    height: 55px;
  }

  /* Style the dropdown indicator */
  .react-select__dropdown-indicator {
    color: #6c757d; /* Match icon color */
  }
`;



export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #6c757d;
`;

export const CustomButton = styled.button`
  background-color: #000000;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  height: 60px;
  color: #ffffff;
  &:hover {
    background-color: #e0a800;
  }
`;

export const Heading = styled.h2`
  background-color: #ffc107; /* Yellow background */
  color: #ffffff; /* White text color */
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;