import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../styles/About.module.css';

const About: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  return (
    <main className={styles.about} style={{ direction: i18n.dir() }}>
      <section className={styles.heroSection}>
        <h1>{t('about')}</h1>
        <p>{t('leading_the_way')}</p>
      </section>
      <section className={styles.contentSection}>
        <div className={`${styles.textContent} ${isRTL ? styles.textContentRTL : ''}`}>
          <h2>{t('mission')}</h2>
          <p>{t('mission_description')}</p>

          <h2>{t('vision')}</h2>
          <p>{t('vision_description')}</p>

          <h2>{t('team')}</h2>
          <p>{t('team_description')}</p>
        </div>
        <div className={styles.imageContent}>
          <img src="team-image.jpg" alt={t('team')} className={styles.teamImage} />
        </div>
      </section>
    </main>
  );
};

export default About;
