import styled from 'styled-components';
export const FooterContainer = styled.footer`
  width: 100%;
  background-color: #14191F;
  padding: 20px 0;
`;

export const FooterText = styled.p`
  margin: 0;
  color: #f5f5f5;
  text-align: center;
`;

export const Separator = styled.hr`
  border: 0;
  border-top: 1px solid #dee2e6;
  margin: 20px 0;
`;

export const Icon = styled.a`
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #495057;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    background-color: #343a40;
  }
`;

export const ImageLink = styled.a`
  display: inline-block;
  margin: 0 10px;
`;

export const Image = styled.img`
  width: 150px;
  height: auto;
`;