import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { SectionContainer, TextContent, Image, BigText, TextContentInside, DetailText, BoxWrapper, Icon } from '../styles/Section.module';
import { DataItem } from '../types';

interface SectionProps {
  imageSrc: string;
  title: string;
  content: string;
  id: string;
  imageLft: boolean;
  contentData: DataItem[];
}

const Section: React.FC<SectionProps> = ({ imageSrc, title, content, imageLft, id, contentData }) => {
  return (
    <SectionContainer id={id}>
      <Row className="align-items-center">
        {imageLft && (
          <Col md={6}>
            <Image src={imageSrc} alt={title} />
          </Col>
        )}
       <Col md={6}>
          <TextContent>
            {contentData.map((item, index) => (
              <TextContentInside key={index}>
                <BoxWrapper>
                  <Icon src={item.icon} alt="icon" />
                  <BigText>{item.subtitle}</BigText>
                  <DetailText>
                    {item.text.split('\n').map((line, idx) => (
                      <React.Fragment key={idx}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </DetailText>
                </BoxWrapper>
              </TextContentInside>
            ))}
          </TextContent>
        </Col>
        {!imageLft && (
          <Col md={6}>
            <Image src={imageSrc} alt={title} />
          </Col>
        )}
      </Row>
    </SectionContainer>
  );
};

export default Section;
