
import { BookingData, VehicleType } from '../types';
import { ServiceConstants } from './ServiceConstants';

const url = ServiceConstants.URL_VEHICLE_SERVICE + ServiceConstants.URL_BOOKING_USER_SEARCH;
const cityurl = ServiceConstants.URL_VEHICLE_SERVICE + ServiceConstants.URL_BOOKING_CITY;
const calculateFareUrl = ServiceConstants.URL_VEHICLE_SERVICE + ServiceConstants.URL_BOOKING_FARE;
const addUrl =
    ServiceConstants.URL_VEHICLE_SERVICE + ServiceConstants.URL_ASSIGNMNET_ENDPOINT;
const BookingService = {
    getBookingSearchList() {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const message = JSON.stringify({
            vehicleType: 1,
        });
        return fetch(url, {
            method: "POST",
            body: message,
            headers: myHeaders,
            redirect: "follow",
        });
    },

    getBookingCityList() {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        return fetch(cityurl, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },

    addBooking(data: BookingData, vehicle: VehicleType, name: string, pickupAddress: string, email: string, phoneNumber: string) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        // const starttime = `${String(data.createStartDate)} ${String(
        //     data.createStartTime
        // )}`;
        // const endtime = `${String(data.createEndDate)} ${String(
        //     data.createEndTime
        // )}`;

        const starttime = data.pickupDate;
        const endtime = data.returnDate;
        console.log("addBooking service updated");
        console.log(starttime);
        console.log(endtime);
        const message = JSON.stringify({
            rentalPStartDate: Date.parse(starttime),
            rentalPEndDate: Date.parse(endtime),
            rentalAStartDate: Date.parse(starttime),
            rentalAEndDate: Date.parse(endtime),
            vehicleId: "",
            startLongitude: data.pickupLocation?.lng,
            startLatitude: data.pickupLocation?.lat,
            startAddress: data.pickupLocation?.id,
            endLongitude: data.destinationLocation?.lng,
            endLatitude: data.destinationLocation?.lat,
            endAddress: data.destinationLocation?.id,
            title: "booking",
            description: "booking",
            rentalCarType: vehicle.id,
            rentalPricePerUnit: vehicle.bookingPricePerUnit,
            rentalUnit: "",
            assignmentType: 2,
            startDate: Date.parse(starttime),
            endDate: Date.parse(endtime),
            userFname:" ",
            userLname:name,
            pickupPhNo: phoneNumber,
            pickupEmail: email,
            pickupAddress: pickupAddress,
            rentalStatus: 1,
            
        });
        console.log("message");
        console.log(message);
        return fetch(addUrl, {
            method: "POST",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
    calculateFare(data: BookingData, vehicle: VehicleType,) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const starttime = data.pickupDate;
        const endtime = data.returnDate;
        console.log("addBooking service updated");
        console.log(starttime);
        console.log(endtime);
        console.log("addBooking service");
        console.log(starttime);
        console.log(endtime);
        const message = JSON.stringify({
            carType: vehicle.id,
            pickupDateTime: Date.parse(starttime),
            dropoffDateTime: Date.parse(endtime),
            driverAge: 40,
            addInsurance: true,
            additionalDrivers: 1,
            mileage: 150,
            gps: false,
            childSeat: false,
            wifi: false,
            fullTankRequired: false,
            paymentMethod: "credit card",
            earlyReturn: false,
            lateReturn: false,
            promotionalDiscount: false,
        });
        console.log("message");
        console.log(message);
        return fetch(calculateFareUrl, {
            method: "POST",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
};

export { BookingService };
