import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import CarList from './pages/CarList';
import { useTranslation } from 'react-i18next';
import BookingSummary from './components/BookingSummary';
import { ConfigProvider } from './context/ConfigContext'; 

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.dir = i18n.dir();
  }, [i18n]);
  return (
    <div>
       <ConfigProvider>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/carlist" element={<CarList />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/booking-summary" element={<BookingSummary />} />
      </Routes>
      <Footer />
      </ConfigProvider>
    </div>
  );
};

export default App;
