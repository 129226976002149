import React from 'react';
import styles from '../styles/CarList.module.css';
import CarItem from '../components/CarItem';

const carData = [
  { id: 1, model: 'Toyota Camry', price: 50 },
  { id: 2, model: 'Honda Accord', price: 55 },
  { id: 3, model: 'Tesla Model 3', price: 75 },
];

const CarList: React.FC = () => {
  return (
    <div className={styles.carList}>
      {carData.map(car => (
        <CarItem key={car.id} model={car.model} price={car.price} />
      ))}
    </div>
  );
};

export default CarList;
