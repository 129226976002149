// context/ConfigContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { ConfigService, AppConfig } from '../services/ConfigService';

interface ConfigContextType {
  config: AppConfig | null;
  loadingC: boolean;
}

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

interface ConfigProviderProps {
  children: ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<AppConfig | null>(null);
  const [loadingC, setLoadingC] = useState<boolean>(true);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const configData = await ConfigService.fetchConfigurations();
        setConfig(configData);
      } catch (error) {
        console.error('Failed to load configurations:', error);
      } finally {
        setLoadingC(false);
      }
    };

    loadConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ config, loadingC }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = (): ConfigContextType => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};
