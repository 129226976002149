import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { BookingData, ConfirmedBookingData, VehicleType } from '../types';
import {
  ModalContainer,
  TripSection,
  DetailColumn,
  CustomButton,
  InfoText,
  VehicleImage,
  VehicleDetails,
  FormSection,
  CloseButton, 
  DetailColumnContainer,
  LoadingContainer,
  ConfirmColumnContainer,
  ConfirmSection,
  ConfirmColumn,
  FareDetailRow
} from '../styles/BookingModal';
import { BookingService } from '../services/BookingService';
import { ServiceConstants } from '../services/ServiceConstants';
import { formatReturnDate } from '../utils/Utils';
import imgConfirmSrc from '../images/confirmicon.png';

interface BookingModalProps {
  showModal: boolean;
  handleModalClose: () => void;
  bookingData: BookingData;
  selectedVehicle: VehicleType;
}

interface IFareData {
  [key: string]: string | number | boolean;
}
const BookingModal: React.FC<BookingModalProps> = ({ showModal, handleModalClose, bookingData, selectedVehicle }) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  // State for form fields
  const [name, setName] = useState('');
  const [pickupAddress, setPickupAddress] = useState('');
  const [email, setEmail] = useState(bookingData.email);
  const [phoneNumber, setPhoneNumber] = useState(bookingData.phone);
  
  // State for errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [fareDetails, setFareDetails] = useState<
  { key: string; value: string | number | boolean }[]
>([]);
  const camelToReadable = (camelCase: string) => {
    return camelCase
        .replace(/([A-Z])/g, " $1") // Insert space before each uppercase letter
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

const calculateFare = async (data: BookingData, vehicleType: VehicleType) => {
    const response = await BookingService
        .calculateFare(data,vehicleType)
        .then((result) => {
            if (result.ok) {
                return result.json();
            }
            const httpErrorInfo = {
                errorstatus: result.status,
                statusText: result.statusText,
                url: result.url,
            };
            return httpErrorInfo;
        })
        .then((myJson) => {
            console.log(myJson);

            if ("errorstatus" in myJson) {
                if (myJson.errorstatus === 401) {
                    console.log("Unauthorized");
                    // setError("Please check username and password");
                }
            } else {
                const adata = myJson as IFareData;
                console.log("adata", adata);
                // Convert the data to key-value pairs with readable keys
                const readableData = Object.entries(adata).map(
                    ([key, value]) => {
                        // Format numbers to two decimal places
                        let formattedValue;
                        if (typeof value === "number") {
                            formattedValue = value.toFixed(2);
                        } else {
                            formattedValue = value;
                        }
                        return {
                            key: camelToReadable(key),
                            value: formattedValue,
                        };
                    }
                );
                // Sort the data to place "Total Fare" last
                const sortedData = readableData.sort((a, b) => {
                    if (a.key === "Total Fare") return 1;
                    if (b.key === "Total Fare") return -1;
                    return 0;
                });
                // Update the state
                setFareDetails(readableData);
            }
        });
};
const handleCalculateFare = () => {
  if(selectedVehicle!=null){
    calculateFare(bookingData, selectedVehicle)
        .then(() => {
            console.log("done");
        })
        .catch((error: TypeError) => {
            console.log(error);
            throw new Error(
                "There was an error retrieving the projects. Please try again."
            );
        });
      }
};
useEffect(() => {
  handleCalculateFare();
}, [selectedVehicle]);
  // Validation function
  const validate = () => {
    const newErrors: { [key: string]: string } = {};

    if (!name) {
      newErrors.name = t('nameIsRequired');
    }

    if (!pickupAddress) {
      newErrors.pickupAddress = t('pickupAddressIsRequired');
    }

    if (!email && !phoneNumber) {
      newErrors.contact = t('emailOrPhoneNumberIsRequired');
    } else {
      if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        newErrors.email = t('invalidEmail');
      }

      if (phoneNumber && !/^\+?\d{10,15}$/i.test(phoneNumber)) {
        newErrors.phoneNumber = t('invalidPhoneNumber');
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) return;

    addBooking(bookingData, selectedVehicle,name,pickupAddress,email,phoneNumber)
      .then(() => {
        console.log('Booking successful');
      })
      .catch((error: TypeError) => {
        console.error('Booking error:', error);
      });
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [bookingCompleted, setBookingCompleted] = useState<boolean>(false);
  const [confirmedBooking, setConfirmedBooking] = useState<ConfirmedBookingData>({bookingId:""});
  const addBooking = async (data: BookingData, vehicleType: VehicleType, name: string, pickupAddress: string, email: string, phoneNumber: string) => {
    setLoading(true);
    const response = await BookingService
      .addBooking(data, vehicleType,name,pickupAddress,email,phoneNumber)
      .then((result) => {
        setLoading(false);
        if (result.ok) {
          return result.json();
        }
        const httpErrorInfo = {
          errorstatus: result.status,
          statusText: result.statusText,
          url: result.url,
        };
        return httpErrorInfo;
      })
      .then((myJson) => {
        console.log(myJson);

        if ("errorstatus" in myJson) {
          if (myJson.errorstatus === 401) {
            console.log("Unauthorized");
          }
        } else {
          setConfirmedBooking(myJson);
          setBookingCompleted(true);
          // Handle success
        }
      });
  };

  const handleConfirmClick = () => {
    setBookingCompleted(false);
    handleModalClose();
  };
  const vehicleimageurl =
  ServiceConstants.URL_VEHICLE_SERVICE + ServiceConstants.URL_VEHICLE_TYPE_IMAGE_ENDPOINT;

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      centered
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('bookingDetails')}</Modal.Title>
      </Modal.Header>
      <ModalContainer rtl={isRTL}>
        <TripSection rtl={isRTL}>
          <DetailColumnContainer>
            <DetailColumn>
              <h3>{t('pickupLocation')}</h3>
              <p>{bookingData.pickupLocation?.city}</p>
              <h3>{t('pickupDate')}</h3>
              <p>{formatReturnDate(bookingData.returnDate)}</p>
              <h3>{t('destinationLocation')}</h3>
              <p>{bookingData.destinationLocation?.city}</p>
              <h3>{t('returnDate')}</h3>
              <p>{formatReturnDate(bookingData.returnDate)}</p>
            </DetailColumn>
          </DetailColumnContainer>
        </TripSection>
        {bookingCompleted ? (
                <ConfirmSection rtl={isRTL}>
                <ConfirmColumnContainer>
                  <ConfirmColumn>
                    <p>{t('bookingSuccessMessage')}</p>
                    <h3>{t('booikg_id')}{confirmedBooking?.bookingId}</h3>
                    <img src={imgConfirmSrc} alt={"confirm"} />
                    <CloseButton type="submit" onClick={() => handleConfirmClick()}>
                  {t('ok')}
                </CloseButton>
                  </ConfirmColumn>
                </ConfirmColumnContainer>
              </ConfirmSection>
              ) : (  
        <FormSection>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>{t('name')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('enterName')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="pickupAddress" className="mb-3">
              <Form.Label>{t('pickupAddress')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('enterPickupAddress')}
                value={pickupAddress}
                onChange={(e) => setPickupAddress(e.target.value)}
                isInvalid={!!errors.pickupAddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pickupAddress}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>{t('email')}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t('enterEmail')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="phoneNumber" className="mb-3">
              <Form.Label>{t('phoneNumber')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('enterPhoneNumber')}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                isInvalid={!!errors.phoneNumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phoneNumber}
              </Form.Control.Feedback>
            </Form.Group>

            {errors.contact && (
              <div className="text-danger mb-3">{errors.contact}</div>
            )}

            <Form.Group controlId="agreeTerms" className="mb-3">
              <Form.Check 
                type="checkbox" 
                required 
                label={
                  <>
                    {t('iAgreeWith')} <a href="/terms" target="_blank" rel="noopener noreferrer">{t('termsAndConditions')}</a> 
                    {' & '}
                    <a href="/cancellation-policy" target="_blank" rel="noopener noreferrer">{t('cancellationPolicy')}</a>
                  </>
                }
              />
            </Form.Group>
            {loading ? (
                <LoadingContainer>
                  <Spinner animation="border" variant="primary" />
                </LoadingContainer>
              ) : (
                <CloseButton type="submit">
                  {t('bookNow')}
                </CloseButton>
             )}
          </Form>
          <InfoText>{t('bookingDetailsSent')}</InfoText>
          <InfoText>{t('payBalanceToDriver')}</InfoText>
        </FormSection>
         )}
        <TripSection rtl={isRTL}>
          <DetailColumnContainer>
            <DetailColumn>
              <h3>{t('selected_Car')}</h3>
              {selectedVehicle && (
  <VehicleDetails>
    <VehicleImage
      src={`${vehicleimageurl}${selectedVehicle.id}`}
      alt={selectedVehicle.type}
    />
    <h3>{selectedVehicle.name}</h3>
    {fareDetails.length > 0 &&
      fareDetails.map((detail, index) => (
        <FareDetailRow
          key={index}
          isLastItem={index === fareDetails.length - 1}
        >
          <span>{detail.key}</span>
          <span>{detail.value}</span>
        </FareDetailRow>
      ))}
  </VehicleDetails>
)}
            </DetailColumn>
          </DetailColumnContainer>
        </TripSection>
      </ModalContainer>
    </Modal>
  );
};

export default BookingModal;
