class ServiceConstants {

    static URL_VEHICLE_SERVICE = "https://back.transitsuite.com";

    static URL_BOOKING_USER_SEARCH = "/bookingusersearch";

    static URL_BOOKING_CITY = "/cities";

    static URL_BOOKING_FARE =  "/calculateFare";

    static URL_ASSIGNMNET_ENDPOINT = "/assignment";

    static URL_VEHICLEIMAGE_ENDPOINT = "/vehicleimage/";

    static URL_VEHICLE_TYPE_IMAGE_ENDPOINT = "/vehicletypeimage/";

    static URL_VEHICLIMAGE_UNCLOSED_ENDPOINT = "/vehicleimage";

    static URL_CONFIGURATION_ENDPOINT = "/company-configurations";
}
export { ServiceConstants };
