import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import styles from '../styles/Header.module.css';
import i18n from '../i18n';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const element = document.getElementById('contact');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScrollWhy = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const element = document.getElementById('whyus');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScrollAbout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const element = document.getElementById('about');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className={styles.header} style={{ direction: i18n.dir() }}>
      <div className={styles.logo}>Zoom Rides</div>
      <nav>
        <ul className={styles.navList}>
          <li><Link to="/">{t('find_car')}</Link></li>
          {/* <li><Link to="/carlist">{t('find_car')}</Link></li>
          <li><Link to="/about">{t('about')}</Link></li> */}
          <li><Link to="#whyus" onClick={handleScrollWhy}>{t('whyUs')}</Link></li>
          <li><Link to="#about" onClick={handleScrollAbout}>{t('about')}</Link></li>
          <li><Link to="#contact" onClick={handleScroll}>{t('contact')}</Link></li>
        </ul>
      </nav>
       <LanguageSwitcher />
    </header>
  );
};

export default Header;
