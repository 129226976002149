// src/pages/Home.tsx
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from '../components/Carousel';
import styles from '../styles/Home.module.css';

// Import local images
import car1 from '../images/home1.jpg';
import car2 from '../images/home2.jpg';
import car3 from '../images/home3.jpg';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import Section from '../components/Section';
import Image1 from '../images/home1.jpg';
import Image2 from '../images/home2.jpg';
import ImageItem1 from '../images/benchmarking.png';
import ImageItem2 from '../images/self-fulfillment.png';
import ImageItem3 from '../images/dependable.png';
import ImageItem4 from '../images/handshake.png';
import ContactUs from '../components/ContactUs';
import TopSection from '../components/TopSection';
import { DataItem } from '../types';
const Home: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  // Array of images to be passed to Carousel
  // Array of images with text properties
  // Sample data
  // const data1: DataItem[] = [
  //   { subtitle: 'Subtitle 1', text: 'This is some text for subtitle 1.' },
  //   { subtitle: 'Subtitle 2', text: 'This is some text for subtitle 2.' },
  //   { subtitle: 'Subtitle 3', text: 'This is some text for subtitle 3.' },
  // ];
  const content = [
    {
      src: Image1,
      alt: t('car_image_1'),
      title: t('whyUs'),
      subtitle: t(''),
      text: t('whyChooseUs'),
      textarray : [
        { subtitle: t('whyChooseUsT1'), text: t('whyChooseUsD1'),icon:ImageItem1 },
        { subtitle: t('whyChooseUsT2'), text: t('whyChooseUsD2'),icon:ImageItem2 },
      ]
    },
    {
      src: Image2,
      alt: t('car_image_2'),
      title: t('about'),
      subtitle: t(''),
      text: t('aboutUs'),
      textarray : [
        { subtitle: t('aboutUsT1'), text: t('aboutUsD1'),icon:ImageItem3 },
        { subtitle: t('aboutUsT2'), text: t('aboutUsD2'),icon:ImageItem4 },
      ]
    }
  ];

  const sectionRef = useRef<HTMLDivElement>(null);
  return (
    <main className={styles.home} style={{ direction: i18n.dir() }}>
      {/* <Carousel images={images} rtl={isRTL} />
      <Carousel images={images} rtl={isRTL} /> */}
      <TopSection />
      <Section id='whyus'
        imageSrc={content[0].src} // Replace with your image URL
        title={content[0].title}
        content={content[0].text}
        imageLft = {true}
        contentData={content[0].textarray}

      />
      <Section id='about'
        imageSrc={content[1].src} // Replace with your image URL
        title={content[1].title}
        content={content[1].text}
        imageLft = {false}
        contentData={content[1].textarray}
      />
      <ContactUs/>
    </main>
  );
};

export default Home;
