import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../styles/Contact.module.css';

const Contact: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  return (
    <main className={styles.contact} style={{ direction: i18n.dir() }}>
      <section className={styles.heroSection}>
        <h1>{t('contact')}</h1>
      </section>
      <section className={styles.formSection}>
        <form className={`${styles.contactForm} ${isRTL ? styles.contactFormRTL : ''}`}>
          <label htmlFor="name">{t('name')}</label>
          <input type="text" id="name" name="name" />

          <label htmlFor="email">{t('email')}</label>
          <input type="email" id="email" name="email" />

          <label htmlFor="message">{t('message')}</label>
          <textarea id="message" name="message" />

          <button type="submit">{t('send')}</button>
        </form>
      </section>
    </main>
  );
};

export default Contact;
