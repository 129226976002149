import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import backgroundImage from '../images/home4.jpg';

interface ModalContainerProps {
  rtl: boolean;
}

interface TripSectionProps {
  rtl: boolean;
}

export const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
`;

export const TripSection = styled.div<TripSectionProps>`
  width: 30%;
  background: url('../images/home4.jpg') no-repeat center center;
  background-size: cover;
  border-radius: 0 0 0 10px;
  padding: 0px;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-allign: center;
  overflow-y: auto;
  position: relative;
  z-index: 2; /* Ensures this section is above the overlay */
  text-align: ${({ rtl }) => (rtl ? 'right' : 'left')};
`;

export const SectionTitle = styled.h2`
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const DetailColumnContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  background-image: url(${backgroundImage});
  background-size: cover;
  position: relative;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   // background: rgba(0, 0, 0, 0.4); /* Slight white overlay with 30% opacity */
  //   z-index: 1;
  // }
`;

export const DetailColumn = styled.div`
text-align: left;
  h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    margin-bottom: 15px;
  }
`;

export const CustomButton = styled(Button)`
  background-color: #ffc107;
  border: none;
  color: black;
  font-weight: bold;
  max-width: 100px;
  margin-top: 20px;
`;

export const InfoText = styled.p`
  font-size: 14px;
  margin-top: 20px;
  color: gray;
`;

export const VehicleImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const VehicleDetails = styled.div`
  text-align: left;

  h3 {
    color: #007bff;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const FormSection = styled.div`
  width: 60%;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 0 10px 10px 0;
  overflow-y: auto;

  form {
    display: flex;
    flex-direction: column;
  }

  .mb-3 {
    margin-bottom: 15px;
  }

  .form-check {
    margin-bottom: 20px;
  }
`;

export const ConfirmSection = styled.div<ModalContainerProps>`
  width: 60%;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 0 10px 10px 0;
  overflow-y: auto;

  form {
    display: flex;
    flex-direction: column;
  }

  .mb-3 {
    margin-bottom: 15px;
  }

  .form-check {
    margin-bottom: 20px;
  }
  text-align: ${({ rtl }) => (rtl ? 'right' : 'left')};
`;

export const ConfirmColumnContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  // background-image: url(${backgroundImage});
  background-size: cover;
  position: relative;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   // background: rgba(0, 0, 0, 0.4); /* Slight white overlay with 30% opacity */
  //   z-index: 1;
  // }
`;

export const ConfirmColumn = styled.div`
text-align: center;
width: 100%;
  h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    margin-bottom: 15px;
  }
    img {
    display: block;
    margin: 0 auto;
    height: 150px;
    width: 150px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
`;

export const CloseButton = styled.button`
  background-color: #ffc107;
  border: none;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  // max-width: 300px;
  width: 100%;
  &:hover {
    background-color: #e0a800;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

export const FareDetailRow = styled.div<{ isLastItem: boolean }>`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  font-weight: ${(props) => (props.isLastItem ? "bold" : "normal")};
  color: ${(props) => (props.isLastItem ? "#007bff" : "black")};
`;
