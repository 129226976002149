import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import tripBackground from '../images/home4.jpg'; // Example path

export const SummaryContainer = styled.div`
  padding: 20px;
  min-height: 100vh;
  background-color: #f8f9fa;
  position: relative;
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const BackButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const TripSection = styled.div`
  margin-bottom: 40px;
  background-color: #ffB921;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-image: url(${tripBackground});
  background-size: cover;
  background-position: center;
`;

export const SectionTitle = styled.h2`
  text-align: center;
  background-color: #ffB921;
  margin-bottom: 20px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 10px;
`;

// export const DetailColumn = styled.div`
//   text-align: center;
// `;

export const DetailColumn = styled.div`
  text-align: center;
  p {
    margin-bottom: 10px; // Add gap below the <p> element
  }

  h3 {
    margin-top: 0; // Ensure no extra margin above <h3>
    margin-bottom: 20px; // Add gap below the <h3> element
  }

  // // Optional: Adjust spacing between <p> and <h3> specifically if needed
  // p + h3 {
  //   margin-top: 20px; // Add gap between <p> and <h3>
  // }
`;
export const IconTextContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;       
export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconText = styled.p`
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: #ffb921;
  text-align: center;
`;

export const VehicleCard = styled.div`
  border: 1px solid #ccc;
  padding: 15px;
  text-align: center;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const VehicleImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 15px;
`;

export const VehicleTypeDiv = styled.h3`
  color: #007bff;
  font-weight: bold;
`;

export const VehiclePrice = styled.p`
  color: #28a745;
  font-weight: bold;
  font-size: 1.2em;
`;

export const CustomButton = styled.button`
  background-color: #ffc107;
  border: none;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  max-width: 200px;
  width: 100%;
  &:hover {
    background-color: #e0a800;
  }
`;