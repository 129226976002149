import React from 'react';
import styles from '../styles/CarItem.module.css';

interface CarItemProps {
  model: string;
  price: number;
}

const CarItem: React.FC<CarItemProps> = ({ model, price }) => {
  return (
    <div className={styles.carItem}>
      <h2>{model}</h2>
      <p>Price per day: ${price}</p>
      <button className={styles.rentButton}>Rent Now</button>
    </div>
  );
};

export default CarItem;
